:root {
  --themecolor: #e42b3b; /* Default theme color */
}

// themecolor
$themecolor: #e42b3b;//var(--themecolor);

$primarybtn: #e42b3b;//var(--themecolor);

$white: #fff;//

$mate-black: #212121;

$black: #000; //474646

$gradient-one: var(--themecolor);

$gradient-two: #0e68cd;//var(--themecolor);

$footer-back-color:#0f0f41;//#9ec2eb;//#c4edcb;

$header-top-color:#0e68cd;

$breadcrumbs-color:#77c3ec;